<template>
  <a-modal v-model="visible" title="新建里程碑" width="680px" destroyOnClose class="createMilestoneModal">
    <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
      <a-form-model-item label="标题" prop="issue_name">
        <a-input v-model="form.issue_name"></a-input>
      </a-form-model-item>
      <div class="line-2">
        <a-form-model-item label="负责人" prop="issue_owner">
          <a-select v-model="form.issue_owner">
            <a-select-option v-for="item in accounts" :key="item.user_oid" :value="item.user_oid">{{ item.title ? item.name + '-' + item.title : item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所属阶段" prop="phase_oid">
          <a-select v-model="form.phase_oid">
            <a-select-option v-for="item in phaseList" :key="item.oid" :value="item.oid">
              {{item.phase_name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="结束日期" prop="deadline">
          <a-date-picker v-model="form.schedule_end_date" style="width: 100%;"/>
        </a-form-model-item>
      </div>
      <a-form-model-item label="描述" prop="issue_desc">
        <a-textarea v-model="form.issue_desc"></a-textarea>
      </a-form-model-item>
    </a-form-model>
    <attachment-upload :source-oid="form.oid" :prj-oid="prjOid" source-type="3">
      <template slot="title">
        附件上传
      </template>
    </attachment-upload>
    <template #footer>
      <div class="modal-footer">
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button type="primary" :loading="loading" @click="submitDebounce">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import attachmentUpload from "@/components/attachmentUpload";
import {fetch} from "@/utils/request";
import catMixin from "@/utils/mixins/catMixin";
import dayjs from "dayjs";
import {debounce} from "lodash";

export default {
  name: "createMilestoneModal",
  components: { attachmentUpload },
  props: {
    prjOid: { // 项目oid 选填
      type: String
    }
  },
  mixins: [catMixin],
  data() {
    return {
      oid: '',
      visible: false,
      rules: {
        issue_name: [
          {required: true, message: "请填写标题", trigger: 'blur'},
        ],
        issue_owner: [
          {required: true, message: "请填写负责人", trigger: 'blur'},
        ],
        phase_oid:[
          {required: true, message: "请填写所属阶段", trigger: 'blur'},
        ],
        issue_level: [
          {required: true, message: "请填写优先级", trigger: 'blur'},
        ],
      },
      form: {
        issue_name: "",
        issue_owner: "",
        issue_owner_name: '',
        issue_level: "",
        phase_oid: "",
        phase_name: "",
        deadline: "",
        issue_desc: "",
        deliverylist: [],
        oid: ''
      },
      backup: {},
      cat: {
        issues_level: [],
        delivery_type: []
      },
      accounts: [],
      loading: false,
      phaseList:[]
    }
  },
  watch: {
    prjOid: {
      immediate: true,
      handler() {
        this.getAccount()
        this.getPhaseList()
      }
    }
  },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
  },
  methods: {
    getAccount() {
      fetch("post", "prj/userlist/list", {
        prjoid: this.prjOid
      }).then(res => {
        if (res.status === 0) {
          this.accounts = res.data
        }
      })
    },
    getPhaseList() {
      fetch("post", "/prj/phase/retrieve", {
        page_size: 999,
        prjoid: this.prjOid
      }).then(res => {
        if (res.status === 0) {
          this.phaseList = res.data.datas
        }
      })
    },
    open(fill = {}) {
      this.visible = true
      this.form = {
        ...this.backup,
        oid: this.$genGuid(),
        ...fill,
        deadline: dayjs().add(1, 'day').format("YYYY/MM/DD")
      }
      let cpy_info = JSON.parse(localStorage.getItem('company_info'))
      if (this.accounts.find(item => item.user_oid === cpy_info.account_oid)) {
        this.form.issue_owner = cpy_info.account_oid
      }
    },
    submitDebounce: debounce(function () {
      this.submit()
    }, 1000, {
      leading: true,
      trailing: false
    }),
    submit() {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log(valid, this.form);
          fetch('post','/prj/issue/create', {
            ...this.form,
            prjoid: this.prjOid,
            issue_type: 'M'
          }).then((res)=>{
            if (res.status === 0) {
              this.$message.success('新增成功！')
              this.visible = false
              this.$emit('reload')
            }else {
              this.$message.error(res.message)
            }
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.loading = false
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.createMilestoneModal {
  /deep/ .ant-modal-body {
    height: 500px;
    overflow-y: auto;
  }
  .line{
    display: block;
    width: 663px;
    height: 23px;
    line-height: 23px;
    background: #F8F8F8;
    font-size: 12px;
    color: #8F8F8F;
    position: relative;
    left: -24px;
    padding-left: 24px;
    margin-bottom: 20px;
  }
  .label {
    font-size: 16px;
    color: #303030;
    margin-bottom: 10px;
  }
  .modal-footer {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .operation{
    color: #4397E7;
    >div{
      cursor: pointer;
    }
  }

  .add {
    color: #4397E7;
    padding: 10px 0 0 17px;
    cursor: pointer;

    >i{
      margin-right: 6px;
    }
  }

  .attachment-upload{
    margin-top: 28px;
  }
}
.relation {
  margin-bottom: 30px;
  .relation-head {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CACACA;
    .relation-name {
      font-size: 14px;
      color: #303030;
    }
    .relation-action {
      margin-left: auto;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .anticon {
        margin-right: 5px;
        font-size: 20px;
      }
    }
  }
  .relation-td {
    display: flex;
    align-items: center;
    >img {
      height: 16px;
      margin-right: 5px;
    }
  }
}
</style>
