<template>
  <div ref="gantt" class="edp-gantt"></div>
</template>

<script>
import gantt from 'dhtmlx-gantt';
import "dhtmlx-gantt/codebase/dhtmlxgantt.css"
let that = null
export default {
  name: "dhtmlx-gantt",
  props: {
    tasks: {
      type: Object,
      default() {
        return {data: [], links: []}
      }
    }
  },
  data() {
    return{
      gantt
    }
  },
  created() {
  },
  mounted: function () {
    that = this
    gantt.config.date_format = "%Y-%m-%d %H:%i:%s"; // 设置日期格式，这里是年月表示年-月-日 时:分:秒的格式
    gantt.config.readonly = true; // 是否只读，表示只能查看任务，不能修改
    gantt.config.duration_unit = "day"; // minute, day, month，设置持续时间的单位，这里设置为"day"，表示以天为单位
    gantt.config.duration_step = 1; //设置持续时间的步长，默认为1
    gantt.config.fit_tasks = true; // 设置是否自适应任务条的宽度，这里设置为true，表示自动调整任务条的宽度以适应时间范围
    gantt.config.open_tree_initially = true; // 默认展开所有树结构
    //gantt.config.resize_rows = true; // 可拖拽高度
    gantt.config.row_height = 48; //设置任务行的高度
    // gantt.config.bar_height = 8;
    gantt.config.min_column_width = 45; //设置列的最小宽度
    gantt.config.scale_height = 47; //设置时间线的高度
    // gantt.config.autosize = "y"; // 自动更改其大小以显示所有任务而无需滚动
    gantt.config.details_on_dblclick = false; //设置双击任务时是否显示详细信息，这里设置为false，禁用双击事件
    gantt.config.show_errors = false; //关闭所有错误提示信息：gantt有自己的异常消息，如果不关闭可能页面会弹出异常消息
    gantt.config.drag_links = false; // 关闭拖放创建依赖关系链接
    // gantt.config.links = true; // 开启链接功能
    gantt.config.drag_move = false; // 关闭拖动任务
    gantt.config.drag_project = false; // 关闭拖动项目
    // gantt.config.scrollable = false;
    // gantt.config.autoscroll = true;
    // gantt.config.grid_width = 600;
    gantt.plugins({
      tooltip: true, // 开启提示：鼠标悬浮在gantt行上显示
      drag_timeline: true,// 拖拽时间线
      marker: true // 开启标记
    });
    gantt.templates.task_row_class = function(){ // 网格添加class
      return "";
    };
    gantt.config.scales = [ // 在时间线上增加一行年份显示
      {unit: "month", step: 1, format: function (date) {
          return `${date.getFullYear()}年${date.getMonth() + 1}月`
        }},
      {unit: "day", step: 1, format: "%j"}
      // , {unit: "day", step: 1, format: function (date) {
      //     let arr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
      //     let week = date.getDay();
      //     let day = arr[week]
      //     return day
      //   }}
    ]
    gantt.templates.task_text = function () { // 修改任务栏内容
      return ``
    };

    gantt.templates.task_class  = function(start, end, task){ // 任务添加class改颜色
      // console.log(task.issue_type);
      switch (task.issue_type){
        case "PH":
          return "edp-phase";
        case "T":
          return task.end_flag === "1" ? "edp-task" : "edp-task-end"
        case "M":
          return task.complete_percent === "100" ? "edp-milestone complete" : "edp-milestone";
      }
    };

    gantt.templates.tooltip_text = function(start,end,task){ // 悬停提示
      return `<div><b>名称：</b>${task.issue_name}</div>
              <div><b>进度：</b>${parseInt(task.progress * 100)}%</div>
              <div><b>开始日期：</b>${gantt.templates.tooltip_date_format(start)}</div>
              <div><b>结束日期：</b>${gantt.templates.tooltip_date_format(end)}</div>`
    };

    gantt.attachEvent("onAfterTaskDrag", function(id, mode){ // 监听拖拽等
      const modes = gantt.config.drag_mode;
      switch (mode){
        case modes.move:
          console.log(id)
          console.log(gantt.getTask(id))
          break;
        case modes.resize:
          console.log(id)
          console.log(gantt.getTask(id))
          return true;
        case modes.progress:
          console.log(id)
          console.log(gantt.getTask(id))
          return true;
      }
    });
    // eslint-disable-next-line no-unused-vars
    gantt.attachEvent("onGanttScroll", function (left, top){
      that.$emit('scroll', gantt.getScrollState().y)
    });
    gantt.config.columns = [ // 表头配置显示列   //name:绑定数据的名称  align：对其方式  label显示在表头的名称
      { name: "issue_name", tree: true, width: '*', align: "left", label: "名称", resize: true, hide: true },
      { name: "start_date", label: '开始时间', align: "center", width: 80, resize: true, hide: true },
      { name: "end_date", label: "结束时间", align: "center", width: 80, resize: true, hide: true },
      { name: "progress", label: "进度", align: "center", width: 80, resize: true, hide: true },
      { name: "duration", label: "持续时间", align: "center", width: 80, resize: true, hide: true },
    ];

    gantt.init(this.$refs.gantt);
  },
  methods: {}
}
</script>

<style scoped lang="less">
.edp-gantt {
  /deep/ .gantt_task_line.gantt_selected {
    box-shadow: none;
  }

  /deep/ .gantt_task_row.gantt_selected {
    background-color: fadeout(@primary-color, 90%);
  }

  /deep/ .gantt_layout_cell{
    border-color: #EDEDED;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left: 0;
  }
  /deep/ .gantt_layout_content{
    .gantt_task_scale {
      background-color: #F8F8F8;
      border-color: #EDEDED;
    }
    .gantt_scale_line{
      border-color: #EDEDED;
    }
    .gantt_scale_cell{
      color: #494949;
    }
  }
  /deep/ .gantt_task_line{
    // 阶段颜色
    &.edp-phase{
      background-color: #C7C7C7;
      border-color: #909090;

      .gantt_task_progress {
        background-color: #606060;
      }
    }

    // 任务有子级颜色
    &.edp-task{
      background-color: #95D8D6;
      border-color: #2CB2AE;

      .gantt_task_progress {
        background-color: #2CB2AE;
      }
    }

    // 任务无子级颜色
    &.edp-task-end{
      background-color: #99C7F2;
      border-color: var(--primary-color);

      .gantt_task_progress {
        background-color: var(--primary-color);
      }
    }

    // 里程碑颜色
    &.edp-milestone{

      &.gantt_milestone{
        background-color: #99C7F2;
        border-color: transparent;
      }

      &.complete{
        position: absolute;
        &.gantt_milestone{
          background-color: #24B47E;
          border-color: transparent;
        }
        .gantt_task_content{
          &::after{
            content: "";
            position: absolute;
            left: 1px;
            top: 4px;
            width: 14px;
            height: 9.5px;
            border: 4px solid #FFF;
            border-top: none;
            border-right: none;
            background: transparent;
            transform: rotate(-93deg);
            z-index: 99;
          }
        }
      }
    }
  }

  /deep/ .marker-today{
    background-color: #F0A100;

    .gantt_marker_content{
      font-weight: 700;
    }
  }
}
</style>

