<template>
  <div class="app-main-content">
    <div class="header">
      <div class="title">
        <h2>全部项目计划与进度<img class="icon" :src="$toUrl('menu/plan.png')"></h2>
        <div>共{{ workitemsSta?.totalcount }}条，其中存在警告提醒的有{{ workitemsSta?.warning_count }}条</div>
      </div>
      <div class="btns">
        <a-button type="primary" @click="openExportModal">
          <a-icon type="download" style="margin-right: 4px;font-size: 18px;"/>
          <!-- <i class="iconfont icon-jia" ></i> -->
          导出
        </a-button>
        <a-button type="primary" @click="openModal" style="margin-left: 15px">
          <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
          里程碑
        </a-button>
        <a-checkbox style="margin-left: 15px" :checked="checked" @change="toggleGantt">
          显隐
        </a-checkbox>
        <a-select style="margin-left: 15px; width: 50px" v-model="ganttDate" size="small" @change="changeGanttDate">
          <a-select-option value="day">
            天
          </a-select-option>
          <a-select-option value="week">
            周
          </a-select-option>
          <a-select-option value="month">
            月
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div ref="content" class="content d-flex">
      <div class="left" ref="leftTable" style="width: 70%;">
        <vxe-table
            v-if="phaseList.length > 0"
            :data="phaseList"
            ref="vxeTable"
            height="100%"
            class="edp-table-warning"
            :tree-config="{expandAll: true,transform: true, parentField: 'parent', iconOpen: 'iconfont icon-xiangxia', iconClose: 'iconfont icon-xiangxia open'}"
            :row-class-name="setRowClassName"
            :row-config="{isHover: true, keyField: 'oid'}"
            :scroll-y="{enabled: true, gt: 50}"
            @scroll="scrollChange"
            @toggle-tree-expand="toggleTreeExpand"
            :expand-config="{reserve: true}"
            :column-config="{resizable: true}"
            show-overflow
            @load-success="handleLoadSuccess"
            >
          <vxe-column field="issue_code" title="任务编码" width="200px">
            <template #default="{row}">
              <a-tooltip v-if="row.issue_type === 'T'">
                <template slot="title">{{ row.issue_code || '暂无' }}</template>
                <edp-input
                    style="--width: 100%"
                    v-model="row.issue_code"
                    :db="dbs.prjIssue"
                    field-name="issue_code"
                    :oid="row.oid"
                    :prj-oid="row.prjoid"
                    @change="reload"
                >
                </edp-input>
              </a-tooltip>
            </template>
          </vxe-column>
          <vxe-column type="seq" title="标题" :width="`${getMaxNum(phaseList) * 20 + 320}px`" tree-node>
            <template #default="{ row }">
              <span class="edp-title">
                <template v-if="row.issue_type_name === '里程碑'">
                 <img v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('milepost_red.png')" alt="">
                 <img v-else-if="row.issue_status === '0'" :src="$toUrl('milepost_blue.png')" alt="">
                 <img v-else-if="row.issue_status === '1'" :src="$toUrl('milepost_yellow.png')" alt="">
                 <img v-else-if="row.issue_status === '100'" :src="$toUrl('milepost_green.png')" alt="">
                </template>
                <template v-else-if="row.issue_type_name === '任务'">
                  <img v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('task_red.png')" alt="">
                  <img v-else-if="row.issue_status === '0'" :src="$toUrl('task_blue.png')" alt="">
                  <img v-else-if="row.issue_status === '1'" :src="$toUrl('task_yellow.png')" alt="">
                  <img v-else-if="row.issue_status === '100'" :src="$toUrl('task_green.png')" alt="">
                </template>
                <img v-else :src="$toUrl('phase.png')" alt="">
                <edp-input
                  ref="table-name"
                  v-if="row.editName && row.issue_type"
                  :db="dbs.prjIssue"
                  :prj-oid="row.prjoid"
                  :oid="row.oid"
                  v-model="row.issue_name"
                  field-name="issue_name"
                  @change="reload"
                ></edp-input>
                <edp-input
                  ref="table-name"
                  v-else-if="row.editName && !row.issue_type"
                  :db="dbs.prjPhase"
                  :prj-oid="row.prjoid"
                  :oid="row.oid"
                  v-model="row.phase_name"
                  field-name="phase_name"
                  @change="reload"
                ></edp-input>
                <template v-else>
                  <a-tooltip>
                    <template slot="title">{{ row.issue_name ? row.issue_name : scope }}</template>
                    <span class="name">{{ row.issue_name ? row.issue_name : scope }}</span>
                  </a-tooltip>
                </template>
              <a-tooltip v-if="!row.editName">
                <template slot="title">修改</template>
                <i class="iconfont icon-wenbenshuru hidden" @click="clickFoucs(row)"></i>
              </a-tooltip>
              <a-tooltip v-if="row.issue_type_name === '阶段'">
                <template slot="title">关联任务项</template>
                <i class="iconfont icon-guanlian hidden" @click="openRelationWorkModal(row)"></i>
              </a-tooltip>
             <template v-if="hasAddTaskP">
                <a-tooltip v-if="row.issue_type_name === '阶段'">
                  <template slot="title">新增任务</template>
                  <img style="cursor: pointer;height: 16px;" :src="$toUrl('mini-add.png')" class="hidden" alt=""
                       @click="openTaskModal(row)">
                </a-tooltip>
                <a-tooltip v-if="row.issue_type_name === '阶段'">
                  <template slot="title">新增里程碑</template>
                  <i class="iconfont icon-V hidden" @click="openModal(row)"></i>
                </a-tooltip>
                <a-tooltip v-if="row.issue_type_name === '任务'">
                  <template slot="title">新增子任务</template>
                    <img style="cursor: pointer;height: 16px;" :src="$toUrl('mini-add.png')" class="hidden" alt=""
                         @click="openChildrenTaskModal(row)">
                </a-tooltip>
             </template>
               <a-tooltip v-if="row.issue_type_name === '任务'">
                <template slot="title">任务详情</template>
                <i class="iconfont icon-liebiao hidden" @click="openTaskDetailModal(row)"></i>
              </a-tooltip>
              <a-tooltip v-if="row.issue_type_name === '任务'">
                <template slot="title">设置前置任务项</template>
                <i class="iconfont icon-guanlian hidden" @click="openRelevancyWorkModal(row)"></i>
              </a-tooltip>
              <a-tooltip v-if="row.issue_type_name === '里程碑'">
                <template slot="title">里程碑详情</template>
                <router-link class="hidden"
                             :to="{path: '/project/plan/milestoneDetails', query: {oid: row.oid, prj_oid}}">
                  <i class="iconfont icon-V"></i>
                </router-link>
              </a-tooltip>
              <a-tooltip v-if="hasDeleteP(row)">
                <template slot="title">删除</template>
                <i class="iconfont icon-shanchuwenjianjia hidden" @click="del(row)"></i>
              </a-tooltip>
            </span>
            </template>
          </vxe-column>
          <vxe-column field="pretasks" width="120px" title="前置任务">
            <template #default="{ row }">
              <a-tooltip v-if="row.issue_type === 'T'">
                <template slot="title">{{ row.pretasks || '暂无' }}</template>
                <edp-input
                    style="--width: 100%"
                    v-model="row.pretasks"
                    :db="dbs.prjIssue"
                    field-name="pretasks"
                    :oid="row.oid"
                    :prj-oid="row.prjoid"
                    @change="reload"
                >
                </edp-input>
              </a-tooltip>
            </template>
          </vxe-column>
          <vxe-column field="issue_type_name" title="类型" width="70px">
            <template #default="{row}">
              <span>{{ row.issue_type_name ? row.issue_type_name : '阶段' }}</span>
            </template>
          </vxe-column>
          <vxe-column field="complete_percent" title="进度" width="80px">
            <template #default="{row}">
              <span v-if="$hasP('T_UPDATEALL') && row.end_flag === '1'">{{ row.complete_percent ? `${row.complete_percent}%` : `` }}</span>
              <edp-input
                  size="mini"
                  v-else
                  number
                  style="--width: 100%"
                  :db="dbs.prjIssue"
                  field-name="complete_percent"
                  v-model="row.complete_percent"
                  :oid="row.oid"
                  :prj-oid="row.prjoid"
                  :precision="0"
              >
                <span>{{ row.complete_percent ? `${row.complete_percent}%` : `` }}</span>
              </edp-input>
            </template>
          </vxe-column>
          <vxe-column field="owners" title="负责人" width="180px">
            <template #default="{row}">
              <div class="edp-owners">
                <div class="d-flex a-i-center" v-for="item in row.owners" :key="item.oid">
                  <a-avatar :size="16" :src="item.pic" />
                  <!--<span>{{ row.issue_type_name }}</span>-->
                  <edp-select
                      style="width: 140px"
                      size="mini"
                      v-model="item.oid"
                      :db="(row.issue_type_name==='阶段'||row.issue_type_name==='')?dbs.prjPhase:dbs.prjIssue"
                      :field-name="(row.issue_type_name==='阶段'||row.issue_type_name==='')?'phase_owner':'issue_owner'"
                      :prj-oid="row.prjoid"
                      :oid="row.oid"
                      :options="principalList"
                      @change="getPhaseList"
                  ></edp-select>
                </div>
              </div>
            </template>
          </vxe-column>
          <vxe-column title="协同人" width="300px">
            <template v-slot="{row}">
              <edp-select
                  v-if="['T'].includes(row.issue_type)"
                  class="synergies"
                  style="width: 100%"
                  :db="dbs.prjIssue"
                  :oid="row.oid"
                  v-model="row.issue_assist"
                  field-name="issue_assist"
                  :prj-oid="row.prjoid"
                  split-str="/"
                  multiple
                  :options="principalList"
                  @change="getPhaseList"
                  :maxTagCount="100"
              ></edp-select>
<!--              <div class="edp-collaborator">-->
<!--                <div v-for="item in row.assists" :key="item.oid">-->
<!--                  <a-avatar :size="16" :src="item.pic" style="margin-right: 5px;" />-->
<!--                  <span class="name">{{ item.title ? item.name + '-' + item.title : item.name }}</span>-->
<!--                </div>-->
<!--              </div>-->
            </template>
          </vxe-column>
          <vxe-column field="schedule_start_date" title="计划开始" width="120px">
            <template #default="{row}">
              <edp-date-picker
                  v-if="row.issue_type === 'T' || row.issue_type_name === '阶段'"
                  style="width: 100%"
                  size="mini"
                  v-model="row.schedule_start_date"
                  :db="dbs.prjIssue"
                  field-name="schedule_start_date"
                  :oid="row.oid"
                  :prj-oid="row.prjoid"
              >
              </edp-date-picker>
            </template>
          </vxe-column>
          <vxe-column field="schedule_end_date" title="计划结束" width="120px">
            <template #default="{row}">
              <edp-date-picker
                  style="width: 100%"
                  size="mini"
                  v-model="row.schedule_end_date"
                  :db="dbs.prjIssue"
                  field-name="schedule_end_date"
                  :oid="row.oid"
                  :prj-oid="row.prjoid"
              >
              </edp-date-picker>
            </template>
          </vxe-column>
          <vxe-column field="actual_start_date" title="实际开始" width="120px">
            <template #default="{row}">
              <edp-date-picker
                  v-if="row.issue_type === 'T' || row.issue_type === 'M'"
                  style="width: 100%"
                  size="mini"
                  v-model="row.actual_start_date"
                  :db="dbs.prjIssue"
                  field-name="actual_start_date"
                  :oid="row.oid"
                  :prj-oid="row.prjoid"
              >
              </edp-date-picker>
            </template>
          </vxe-column>
          <vxe-column field="actual_end_date" title="实际结束" width="120px">
            <template #default="{row}">
              <edp-date-picker
                  v-if="row.issue_type === 'T' || row.issue_type === 'M'"
                  style="width: 100%"
                  size="mini"
                  v-model="row.actual_end_date"
                  :db="dbs.prjIssue"
                  field-name="actual_end_date"
                  :oid="row.oid"
                  :prj-oid="row.prjoid"
              >
              </edp-date-picker>
            </template>
          </vxe-column>
          <vxe-column title="创建时间" field="create_time" width="120px">
          </vxe-column>
        </vxe-table>
      </div>
<!--      <edp-empty v-show="phaseList.length <= 0" style="margin-top: 30px;"></edp-empty>-->
      <div class="gantt-box" ref="rightGantt">
        <dhtmlx-gantt v-if="!checked" ref="dhtmxGantt" @scroll="ganttScrollChange" :tasks="ganntList"></dhtmlx-gantt>
      </div>
    </div>
    <create-milestone-modal ref="modal" :prj-oid="prj_oid" @reload="reload"></create-milestone-modal>
    <create-export-modal ref="exportModal" :prj-oid="prj_oid" :phaseList='phaseList'></create-export-modal>
    <create-task-modal ref="taskModal" @reload="reload"></create-task-modal>
    <relevancy-work-modal ref="relevancyWorkModal" @reload="reload"/>
    <relation-work-modal ref="relationWorkModal" :prj-oid="prj_oid" @reload="reload"></relation-work-modal>
    <task-modal @reload="reload" ref="taskDetailModal"></task-modal>
  </div>
</template>

<script>
import createMilestoneModal from "@/components/createMilestoneModal";
import createExportModal from "@/components/createExportModal";
import {fetch} from "@/utils/request";
import CreateTaskModal from "@/components/createTaskModal.vue";
import relationWorkModal from "@/components/relationWorkModal.vue";
import TaskModal from "@/components/taskModal.vue";
import dhtmlxGantt from "@/components/dhtmlx-gantt.vue";
import gantt from "dhtmlx-gantt";
import {FieldUpdateDb} from "@/utils/FieldUpdate";
import EdpInput from "@/components/e-form/edp-input.vue";
import EdpSelect from "@/components/e-form/edp-select.vue";
import edpDatePicker from "@/components/e-form/edp-date-picker.vue";
import catMixin from "@/utils/mixins/catMixin";
import RelevancyWorkModal from "@/components/relevancyWorkModal.vue";
import {genGuid} from '@/utils/helpFunc'
export default {
  name: "projectPlanList",
  mixins: [catMixin],
  data() {
    return {
      dbs: {
        prjPhase: new FieldUpdateDb("prj", "prj_phase"),
        prjIssue: new FieldUpdateDb("prj", "prj_issue"),
      },
      phaseList: [],
      tableHeight: 0,
      workitemsSta: {},
      prj_oid: '',
      phaseData: {},
      ganntList: {
        data: [],
        links: []
      },
      checked: false,
      ganttDate: 'day',
      cat: {
        issues_level: [],
      },
      principalList: [],
      tableScrollHeight: null,
      tableInterval: null
    }
  },
  components: {
    RelevancyWorkModal,
    // eslint-disable-next-line vue/no-unused-components
    EdpInput,
    TaskModal,
    CreateTaskModal,
    createMilestoneModal,
    createExportModal,
    relationWorkModal,
    dhtmlxGantt,
    // eslint-disable-next-line vue/no-unused-components
    EdpSelect,
    // eslint-disable-next-line vue/no-unused-components
    edpDatePicker
  },
  computed: {
    issues_level_list() {
      return this.cat.issues_level.slice().reverse();
    },
    hasAddTaskP() {
      return this.$hasP('T_CREATE')
    },
  },
  created() {
    this.prj_oid = this.$route.query.prj_oid
    this.getPrincipal()
  },
  mounted() {
    if (this.prj_oid) {
      this.getPhaseList()
      this.getGanttList(() => {
        this.$refs.dhtmxGantt.gantt.clearAll() //清空数据
      })
      this.getWorkitemSta(this.prj_oid)
    }
  },
  beforeDestroy() {

  },
  methods: {
    scrollChange({scrollTop}) {
      console.log('表格',scrollTop);
      if (scrollTop) this.$refs.dhtmxGantt.gantt.scrollTo(null, scrollTop)
    },
    ganttScrollChange(scrollTop) {
      console.log('甘特图',scrollTop);
      if (scrollTop) this.$refs.vxeTable.scrollTo(null, scrollTop)
    },
    toggleTreeExpand({expanded, row}) {
      // console.log(expanded, row);
      expanded ? this.$refs.dhtmxGantt.gantt.open(row.id) : this.$refs.dhtmxGantt.gantt.close(row.id)
    },
    openRelevancyWorkModal(row) {
      this.$refs.relevancyWorkModal.prjoid = row.prjoid
      this.$refs.relevancyWorkModal.open(row)
    },
    // 获取负责人
    async getPrincipal() {
      const res = await fetch('post', '/prj/userlist/list', {prjoid: this.prj_oid})
      if (res.status === 0) {
        this.principalList = res.data.map(item => {
          return {
            label: item.title ? item.name + "-" + item.title : item.name,
            value: item.user_oid,
            key: item.user_oid
          }
        })
      }
    },
    hasDeleteP(row) {
      let oid = JSON.parse(localStorage.getItem("company_info")).account_oid
      if (row.issue_type_name === '任务') {
        if (row.issue_owner === oid || row.creator_oid === oid) {
          return true
        }
        return this.$hasP("T_DELALL")
      } else if (row.issue_type_name === '阶段') {
        if (row.phase_owner?.split('/').includes(oid) || row.creator_oid === oid) {
          return true
        }
        return this.$hasP("H_DEL")
      } else {
        return true
      }
    },
    getPhaseName(phase_oid) {
      if (phase_oid in this.phaseData) {
        return this.phaseData[phase_oid]
      }
      return ""
    },
    openTaskDetailModal(row) {
      this.$refs.taskDetailModal.open(row.oid)
    },
    // 获取项目阶段列表
    getPhaseList() {
      fetch('post', '/prj/phase/retrieve/byprj', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          this.phaseData = []
          data.forEach(e => {
            this.phaseData[e.oid] = e.phase_name
          })
          console.log(this.phaseData);
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取甘特图列表
    getGanttList(cb) {
      fetch('post', '/prj/phase/issue/retrieve/chart', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          this.phaseList = data
          this.ganntList.data = data.map(e => {
            return {
              ...e,
              start_date: e.schedule_start_date || '',
              end_date: e.schedule_end_date || '',
              progress: e.complete_percent / 100,
              type: e.issue_type === 'M' ? 'milestone' : '',
              bar_height: e.issue_type === 'M' ? 28 : 8,
              duration: e.issue_type === 'M' ? 1 : 0
            }
          })
          this.ganntList.links = []
          let filterArr = []
          filterArr = data.filter(e=> e.issue_code !== 'APPNULL' && e.issue_code)
          filterArr.forEach(e => {
            let pretasks = e.pretasks.split(',')
            pretasks.forEach(e1=>{
              let temp = this.getGanttId(e1, filterArr)
              if (temp) {
                this.ganntList.links.push({
                  target_code: e.issue_code,
                  source_code: e1,
                  id: genGuid(),
                  source: temp,
                  target: e.id,
                  type: '0'
                })
              }
            })
          })
          if (cb) cb()
          gantt.addMarker({ //显示当天刻度线
            start_date: new Date(),
            css: "marker-today",//today样式class为自带，其他的时间标识线需要自己定义class
            text: "今天",
            title: "今天: "
          })
          this.$refs.dhtmxGantt.gantt.parse(this.ganntList)
          // this.$refs.dhtmxGantt.gantt.refreshData()
          if (this.tableScrollHeight && this.tableScrollHeight > 0) {
            this.tableInterval = setInterval(()=>{
              if (this.tableScrollHeight === this.$refs.vxeTable.getScroll().scrollTop) {
                clearInterval(this.tableInterval)
                this.tableScrollHeight = null
              }
              this.$nextTick(()=>{
                this.ganttScrollChange(this.tableScrollHeight)
              })
            },10)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getGanttId(code, arr=[]) {
      let temp = code.split(',')
      return arr.find(e => temp.includes(e.issue_code))?.oid
    },
    getMaxNum(data) {
      let arr = data.filter(e=> e.route_level !== 'APPNULL').map(e=> e.route_level-0)
      const max = arr.reduce((acc, curr) => Math.max(acc, curr), 0);
      console.log(max);
      return max
    },
    getWorkitemSta(prjoid) {
      fetch('post', '/prj/issue/workitems/sta', {
        prjoid
      }).then((res) => {
        if (res.status === 0) {
          this.workitemsSta = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    openModal(row) {
      this.$refs.modal.open({
        phase_oid: row.oid,
        phase_name: row.phase_name
      })
    },
    // 打开导出对话框
    openExportModal(row) {
      this.$refs.exportModal.open({
        phase_oid: row.oid,
        phase_name: row.phase_name
      })
    },
    openTaskModal(row) {
      this.$refs.taskModal.open({
        prjoid: row.prjoid,
        phase_name: this.getPhaseName(row.oid),
        phase_oid: row.oid
      })
    },
    openChildrenTaskModal(row) {
      this.$refs.taskModal.open({
        prjoid: row.prjoid,
        route_level: row.route_level - 0 + 1,
        route_map: row.route_map + '/' + row.oid,
        phase_name: this.getPhaseName(row.phase_oid),
        phase_oid: row.phase_oid
      })
    },
    openRelationWorkModal(row) {
      this.$refs.relationWorkModal.open(row)
    },
    del(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          console.log(row?.issue_type_name);
          let url = '/prj/phase/delete'
          if (row?.issue_type_name === '需求') {
            url = '/prj/requirement/delete'
          } else if (row?.issue_type_name === '里程碑' || row?.issue_type_name === '任务') {
            url = '/prj/issue/delete'
          }
          fetch('post', url, {
            oid: row.oid
          }).then((res) => {
            if (res.status === 0) {
              this.queryTree(this.phaseList, row.oid)
              this.$message.success('删除成功！')
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    },
    queryTree(datas, oid) { // 递归循环查树结构元素
      datas.forEach((item, index) => {
        if (item.oid === oid) {
          // console.log(datas, datas[index]);
          this.$delete(datas, index)
          return;
        }
        if (item.children) {  //存在子节点就递归
          this.queryTree(item.children, oid);
        }
      })
    },
    // 设置每列延期样式
    setRowClassName({row}) {
      return row.is_later === '1' ? 'edp-warning' : ''
    },
    clickFoucs(row) {
      // console.log(row);
      this.$set(row, 'editName', true)
      this.$nextTick(() => {
        this.$refs["table-name"].$refs.input.focus()
      })
    },
    fieldUpdate(row) {
      // console.log(row);
      this.$set(row, 'editName', false)
      if (row.phase_name) {
        this.$updateOnefield('prj', 'prj_phase', 'phase_name', row.phase_name, row.oid, row.prjoid)
      } else {
        this.$updateOnefield('prj', 'prj_issue', 'issue_name', row.issue_name, row.oid, row.prjoid)
      }
    },
    reload() {
      console.log(this.$refs.vxeTable.getScroll());
      this.tableScrollHeight = this.$refs.vxeTable?.getScroll().scrollTop
      this.phaseList = []
      this.getGanttList(()=>{
        this.$refs.dhtmxGantt.gantt.clearAll() //清空数据

      })
    },
    toggleGantt() {
      this.checked = !this.checked;
    },
    changeGanttDate() {
      // console.log(this.ganttDate);
      // console.log(this.$refs.dhtmxGantt);
      if (this.ganttDate === 'week') {
        let weekScaleTemplate = function (date) {
          let dateToStr = gantt.date.date_to_str("%d");
          let endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
          return `${dateToStr(date)} - ${dateToStr(endDate)}`;
        }
        this.$refs.dhtmxGantt.gantt.config.scales = [
          {
            unit: "month", step: 1, format: function (date) {
              return `${date.getFullYear()}年${date.getMonth() + 1}月`
            }
          },
          {unit: "week", step: 1, format: weekScaleTemplate}
        ]
      } else if (this.ganttDate === 'day') {
        this.$refs.dhtmxGantt.gantt.config.scales = [
          {
            unit: "month", step: 1, format: function (date) {
              return `${date.getFullYear()}年${date.getMonth() + 1}月`
            }
          },
          {unit: "day", step: 1, format: "%j"}
        ]
      } else if (this.ganttDate === 'month') {
        this.$refs.dhtmxGantt.gantt.config.scales = [
          {
            unit: "year", step: 1, format: function (date) {
              return `${date.getFullYear()}年`
            }
          },
          {
            unit: "month", step: 1, format: function (date) {
              return `${date.getMonth() + 1}月`
            }
          }
        ]
      }

      this.$refs.dhtmxGantt.gantt.init(this.$refs.dhtmxGantt.$el)
    },
    handleLoadSuccess(row) {
      console.log('加载完成', row)
    }
  }
}
</script>

<style scoped lang="less">
.mini-number-input {

}

.app-main-content {
  padding: 0 7px;
  box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.50);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 46px 12px 20px;
    background: #FFFFFF;
    border-radius: 4px 4px 0 0;
    font-weight: 500;
    color: #494949;

    .title {
      font-size: 12px;

      h2 {
        margin: 0 0 5px 0;
        font-size: 18px;
        font-weight: 500;
        color: #494949;
        display: flex;
        align-items: center;

        .icon {
          margin-left: 10px;
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .btns {
    button {
      font-weight: 500;
      font-size: 14px;
      border-radius: 3px;
      //height: 30px;
      //line-height: 30px;
      //color: #FFFFFF;
      //background-color: var(--primary-color);
      //border-color: var(--primary-color);
    }
  }

  .content {
    height: calc(100% - 87px);
    background: #FFFFFF;
    overflow-y: auto;
    display: flex;

    > .left {
      width: 70%;
      overflow-y: auto;

      /deep/ .ant-table-body {
        .ant-table-tbody {
          tr {
            td {
              white-space: nowrap;
            }
          }
        }
      }

      /deep/ .vxe-table{
        width: 100%;
      }
    }

    > .gantt-box {
      width: 30%;
      height: 100%;

      .edp-gantt{
        height: 100%;
      }
      //overflow-x: auto;
      //overflow-y: auto;
    }
  }

  /deep/ .vxe-table--render-default {
    .vxe-table--header-wrapper {
      background-color: #f8f8f8;
      .vxe-body--x-space {
        background-color: #f8f8f8;
      }
      .vxe-header--column {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .vxe-body--row{
      &.row--current {
        background-color: initial;
      }
      &.row--hover {
        background-color: #fff4e6;
      }

      .vxe-tree--btn-wrapper {
        display: flex;
      }

      .edp-title{
        display: flex;
        align-items: center;

        > .name{
          flex: 1;
          max-width: none;
        }
      }

      .edp-collaborator{
        display: flex;
        width: 100%;

        > div{
          display: flex;
          flex-shrink: 0;
          align-items: center;
          margin-right: 5px;

          .name{
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:nth-last-child(1){
            margin-right: 0;
          }
        }
      }

      .synergies{
        .ant-select-selection__rendered{
          > ul{
            display: flex;
            overflow: hidden;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
