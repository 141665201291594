import { render, staticRenderFns } from "./dhtmlx-gantt.vue?vue&type=template&id=6b7c9315&scoped=true&"
import script from "./dhtmlx-gantt.vue?vue&type=script&lang=js&"
export * from "./dhtmlx-gantt.vue?vue&type=script&lang=js&"
import style0 from "./dhtmlx-gantt.vue?vue&type=style&index=0&id=6b7c9315&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b7c9315",
  null
  
)

export default component.exports