<template>
    <a-modal v-model="visible" title="导出" destroyOnClose class="createMilestoneModal">
        <a-table
            :defaultExpandAllRows="true"
            rowKey="oid"
            :expandIconColumnIndex="1"
            :data-source="phaseList"
            :pagination="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange , onSelectAll:onSelectAll}">
            <a-table-column title="标题" min-width="280px" data-index="phase_name">
              <template v-slot:default="_, row" >
                <div class="edp-title">
                  <img v-if="row.is_later === '1'" :src="$toUrl('renwu_later.png')" alt="">
                  <img v-else-if="row.complete_percent === '100'" :src="$toUrl('renwu_complete.png')" alt="">
                  <img v-else :src="$toUrl('renwu.png')" alt=""> 
                  <span style="font-weight: 500">{{row.phase_name}}</span>
                  <a-tooltip>
                    <template slot="title">{{ row.issue_name }}</template>
                    <span class="name" style="font-weight: 500">{{ row.issue_name }}</span>
                  </a-tooltip>
                  <!-- <a-tooltip>
                    <template slot="title">新增子任务</template>
                    <img style="cursor: pointer;height: 16px;" :src="$toUrl('mini-add.png')" class="hidden" alt="" @click="openChildrenTaskModal(row)">
                  </a-tooltip> -->
                </div>
              </template>
            </a-table-column>
            <a-table-column data-index="issue_type_name" min-width="80px" >
                <template #title>
                    类型 <a-checkbox @change="onChange('type_name', $event)"></a-checkbox>
                </template>
            </a-table-column>
            <a-table-column min-width="60px">
                <template #title>
                    进度 <a-checkbox @change="onChange('complete_percent', $event)"></a-checkbox>
                </template>
              <template v-slot="_, row">
                <div>{{row.complete_percent || 0}}%</div>
              </template>
            </a-table-column>
            <a-table-column min-width="90px">
                <template #title>
                    负责人 <a-checkbox @change="onChange('owner_name', $event)"></a-checkbox>
                </template>
              <template v-slot:default="_, row">
                <div class="edp-owners">
                  <div v-for="item in row.owners" :key="item.oid">
                    <a-avatar :size="16" :src="item.pic" />
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column data-index="优先级" min-width="70px">
              <template #title>
                优先级 <a-checkbox @change="onChange('level_name', $event)"></a-checkbox>
              </template>
              <template v-slot:default="_, row">
                <div
                    v-if="row.issue_level"
                    class="edp-tag"
                    :class="{
                    green: row.issue_level === '100',
                    yellow: row.issue_level === '300',
                    blue: row.issue_level === '200',
                    red: row.issue_level === '400',
                  }"
                >
                  {{ row.issue_level_name }}
                </div>
                <!-- <div v-else class="edp-tag">未设置</div> -->
              </template>
            </a-table-column>
            <a-table-column data-index="schedule_start_date" min-width="90px">
                <template #title>
                    计划开始
                    <a-checkbox  @change="onChange('schedule_start_date', $event)"></a-checkbox>
                </template>
            </a-table-column>
            <a-table-column data-index="schedule_end_date" min-width="90px">
                <template #title>
                    计划结束 <a-checkbox @change="onChange('schedule_end_date', $event)"></a-checkbox>
                </template>
            </a-table-column>
            <a-table-column data-index="actual_end_date" min-width="90px">
                <template #title>
                    实际结束 <a-checkbox @change="onChange('actual_end_date', $event)"></a-checkbox>
                </template>
            </a-table-column>
        </a-table>
      <template #footer>
        <div class="modal-footer">
          <a-space>
            <a-button @click="visible = false">
              取消
            </a-button>
            <a-button type="primary" :loading="loading" @click="exportExcel">
              确定
            </a-button>
          </a-space>
        </div>
      </template>
    </a-modal>
  </template>
  
  <script>
  import {fetch} from "@/utils/request";
  import catMixin from "@/utils/mixins/catMixin";
  import dayjs from "dayjs";
  import {debounce} from "lodash";
  import { queryChildren } from "@/utils/helpFunc";
  import appsettings from '../../src/utils/appsettings'


  export default {
    name: "createMilestoneModal",
    props: {
      prjOid: { // 项目oid 选填
        type: String
      },
      phaseList: {
        type: Array
      }
    },
    mixins: [catMixin],
    data() {
      return {
        oid: '',
        list:[],
        selectedRowKeys: [],
        selectedColumn: [],
        allRowslength:0,
        selectAll: false,
        visible: false,
        rules: {
          issue_name: [
            {required: true, message: "请填写标题", trigger: 'blur'},
          ],
          issue_owner: [
            {required: true, message: "请填写负责人", trigger: 'blur'},
          ],
          phase_oid:[
            {required: true, message: "请填写所属阶段", trigger: 'blur'},
          ],
          issue_level: [
            {required: true, message: "请填写优先级", trigger: 'blur'},
          ],
        },
        form: {
          issue_name: "",
          issue_owner: "",
          issue_owner_name: '',
          issue_level: "",
          phase_oid: "",
          phase_name: "",
          deadline: "",
          issue_desc: "",
          deliverylist: [],
          oid: ''
        },
        backup: {},
        cat: {
          issues_level: [],
          delivery_type: []
        },
        accounts: [],
        loading: false,
        // phaseList:[]
      }
    },
    watch: {
      prjOid: {
        immediate: true,
        handler() {
          this.getAccount()
          this.getPhaseList()
        }
      },
      visible: {
        handler() {
          this.selectedColumn=[],
          this.selectedRowKeys=[]
        }
      }
    },
    created() {
      this.backup = JSON.parse(JSON.stringify(this.form))
    },
    mounted(){
      // this.getPhaseList()
    },
    methods: {
      getAccount() {
        fetch("post", "prj/userlist/list", {
          prjoid: this.prjOid
        }).then(res => {
          if (res.status === 0) {
            this.accounts = res.data
          }
        })
      },
      // 导出时点击全选后发送给后端的selectedRowKeys应该是空数组,
      onSelectAll(selected){
        console.log("全选了？---",selected);
        console.log("选中数据的数量---",this.selectedRowKeys.length);
        if(selected){
          this.selectAll = true 
          this.allRowslength = this.selectedRowKeys.length
        } 
        if(!selected)  this.selectAll = false
        console.log(this.selectAll);
      },
      // 取消选中需要删除selectedColumn的对应元素
      cancleColumm(field_name){
        field_name = field_name.toString()
          for (let i = this.selectedColumn.length - 1; i >= 0; i--) {
            if (this.selectedColumn[i].includes(field_name)) {
              this.selectedColumn.splice(i, 1);
            }
          }
      },
      onChange(field_name, e){
        if (e.target.checked) {
          // console.log("选中列");
          // 选中该列需要获取列的列的字段名---column，多个用英文逗号隔开
          this.$set(this, field_name, e.target.checked)
          field_name = field_name.toString()
          this.selectedColumn.push(field_name)
          console.log(this.selectedColumn);
        }else{
          // console.log("取消选中");
          field_name = field_name.toString()
          // 取消选中需要删除selectedColumn的对应元素
          this.cancleColumm(field_name)
          console.log(this.selectedColumn);
        }
      },
      onSelectChange(selectedRowKeys) {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.selectedRowKeys = selectedRowKeys;
      },
        //导出数据
        exportExcel(){
            this.selectedColumn.unshift("name")
            // 将用户选择的列进行排序
            const columnOrder = ["name", "type_name", "complete_percent", "owner_name", "level_name", "schedule_start_date", "schedule_end_date", "actual_end_date"];
            this.selectedColumn.sort((a, b) => {
              return columnOrder.indexOf(a) - columnOrder.indexOf(b);
            });

            let row = this.selectedRowKeys.join(',')
            // 选择全选按钮后需要传空值
            if (this.selectAll && this.selectedRowKeys.length === this.allRowslength) row = ''
            let column = this.selectedColumn.join(',')
            const token = localStorage.getItem('token')
            window.open(appsettings.apiroot + 'prj/phase/export?token=' + token + '&prjoid=' + this.prjOid + '&oid=' + row + '&column=' + column)
            this.visible = false;
        },
       // 获取项目阶段列表
        getPhaseList() {
        fetch('post', '/prj/phase/issue/retrieve', {
            prjoid: this.prjOid
        }).then((res) => {
            if (res.status === 0) {
            let data = res.data
            this.phaseData = []
            this.phaseList = data.map(e => {
                this.phaseData[e.oid] = e.phase_name
                return { ...e, editName: false, issue_type_name: "阶段" }
            })
            console.log(this.phaseData);
            queryChildren(this.phaseList)
            } else {
            this.$message.error(res.message)
            }
        })
        },
      open(fill = {}) {
        this.visible = true
        this.form = {
          ...this.backup,
          oid: this.$genGuid(),
          ...fill,
          deadline: dayjs().add(1, 'day').format("YYYY/MM/DD")
        }
        let cpy_info = JSON.parse(localStorage.getItem('company_info'))
        if (this.accounts.find(item => item.user_oid === cpy_info.account_oid)) {
          this.form.issue_owner = cpy_info.account_oid
        }
      },
      submitDebounce: debounce(function () {
        this.submit()
      }, 1000, {
        leading: true,
        trailing: false
      }),
      submit() {
        this.loading = true
        this.$refs.form.validate(valid => {
          if (valid) {
            console.log(valid, this.form);
            fetch('post','/prj/issue/create', {
              ...this.form,
              prjoid: this.prjOid,
              issue_type: 'M'
            }).then((res)=>{
              if (res.status === 0) {
                this.$message.success('新增成功！')
                this.visible = false
                this.$emit('reload')
              }else {
                this.$message.error(res.message)
              }
            }).finally(() => {
              this.loading = false
            })
          } else {
            this.loading = false
          }
        });
      },
    }
  }
  </script>
  
  <style lang="less" scoped>
  .createMilestoneModal {
    /deep/ .ant-modal{
        width: 1200px !important;
    }
    /deep/ .ant-modal-body {
      min-height: 200px;
    //   overflow-y: auto;
    }
    .line{
      display: block;
      width: 663px;
      height: 23px;
      line-height: 23px;
      background: #F8F8F8;
      font-size: 12px;
      color: #8F8F8F;
      position: relative;
      left: -24px;
      padding-left: 24px;
      margin-bottom: 20px;
    }
    .label {
      font-size: 16px;
      color: #303030;
      margin-bottom: 10px;
    }
    .modal-footer {
      display: flex;
      justify-content: right;
      align-items: center;
    }
  
    .operation{
      color: #4397E7;
      >div{
        cursor: pointer;
      }
    }
  
    .add {
      color: #4397E7;
      padding: 10px 0 0 17px;
      cursor: pointer;
  
      >i{
        margin-right: 6px;
      }
    }
  
    .attachment-upload{
      margin-top: 28px;
    }
  }
  .relation {
    margin-bottom: 30px;
    .relation-head {
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #CACACA;
      .relation-name {
        font-size: 14px;
        color: #303030;
      }
      .relation-action {
        margin-left: auto;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .anticon {
          margin-right: 5px;
          font-size: 20px;
        }
      }
    }
    .relation-td {
      display: flex;
      align-items: center;
      >img {
        height: 16px;
        margin-right: 5px;
      }
    }
  }
  </style>
  