<template>
  <a-modal title="关联任务项" v-model="visible" width="1000px" wrapClassName="relationWorkModal" @ok="relatePhase">
    <div class="relation-work">
      <div class="header">
        <a-checkbox :checked="allSelect" @change="allSelect = $event.target.checked">
          全选
        </a-checkbox>
        <a-input-search class="search-input" placeholder="" style="width: 200px" v-model="search" />
      </div>
      <div class="work-list">
        <div class="work-item" v-for="(item, key) in filteredList" :key="key" @click="toggleRowSelect(item)">
          <a-checkbox :checked="item.checked" @change="item.checked = $event.target.checked"></a-checkbox>
          <div class="text-block gray xk" v-if="item?.owners?.length > 0">{{ item.owners[0]?.name }}</div>
          <div class="text-block"
               :class="{
                green: item.issue_level === '100',
                yellow: item.issue_level === '200',
                blue: item.issue_level === '300',
                red: item.issue_level === '400',
              }">{{ item.issue_level_name }}
          </div>
          <div class="text-block xk">阶段：{{ item.phase_name }}</div>
          <a-tooltip>
            <template slot="title">工作项类型：{{ item.route_level === '0' ? '任务' : '子任务' }}</template>
            <img class="icon" :src="$toUrl('renwu.png')">
          </a-tooltip>
          <div class="text-block gray xk">{{ item.issue_name }}</div>
          <div class="status blue">{{ item.issue_status_name }}</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { fetch } from "@/utils/request";

export default {
  name: "relationWorkModal",
  props: {
    prjOid: {
      type: String
    },
  },
  data() {
    return {
      visible: false,
      phase_oid: '', // 阶段oid
      list: [],
      search: ''
    }
  },
  methods: {
    toggleRowSelect(item) {
      item.checked = !item.checked
    },
    open(row) {
      let { oid: phase_oid } = row
      console.log(row);
      this.phase_oid = phase_oid
      this.visible = true
      this.getWorkList()
    },
    getWorkList() {
      fetch("post", "/prj/issue/retrieve", { prjoid: this.prjOid, page_size: 999 }).then(res => {
        if (res.status === 0) {
          let data = res.data.datas
          console.log('所有任务', data)
          this.list = data.map(e => {
            return { ...e, checked: this.phase_oid === e.phase_oid }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    relatePhase() {
      fetch("post", "/prj/issue/relate/phase", { phase_oid: this.phase_oid, list: this.selectList }).then(res => {
        if (res.status === 0) {
          this.visible = false
          this.$emit('reload')
          this.$message.success('关联成功！')
        } else {
          this.$message.error(res.message)
        }
      })
    }
  },
  computed: {
    allSelect: {
      get() {
        return this.selectList.length === this.list.length && this.list.length > 0
      },
      set(val) {
        console.log(val)
        for (let item of this.list) {
          item.checked = val
        }
      }
    },
    selectList() {
      return this.list.filter(item => item.checked === true).map(item => {
        return {
          oid: item.oid,
          issue_type: item.issue_type
        }
      })
    },
    filteredList() {
      const filter = (item) => item.issue_name.includes(this.search) || item.phase_name.includes(this.search)
      return this.list.filter(filter)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .relationWorkModal {
  .ant-modal-body {
    padding: 0;
  }
}

/deep/ .ant-checkbox .ant-checkbox-input {
  border: none;
  width: 12px;
  height: 12px;
  border: 1px solid #979797;
  border-radius: 2px;
}

.relation-work {
  .header {
    background-color: #F8F8F8;
    border-bottom: 1px solid #EDEDED;
    padding: 0 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-checkbox-wrapper {
      font-weight: 500;
      font-size: 14px;
      color: #303030;
    }
  }

  .work-list {
    height: 400px;
    overflow-y: auto;

    .work-item {
      padding: 0 20px;
      display: flex;
      height: 45px;
      align-items: center;
      border-bottom: 1px solid #EDEDED;;

      &:nth-child(2n) {
        background-color: #F8F8F8;
      }

      .ant-checkbox-wrapper {
        pointer-events: none;
        margin-right: 8px;
      }

      .text-block {
        margin-right: 10px;
        font-weight: 500;
        font-size: 12px;
        color: #303030;

        &.xk {
          max-width: 170px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }

        &.gray, &.blue, &.red, &.green, &.yellow {
          padding: 2px 5px;
          border-radius: 4px;
        }

        &.gray {
          background-color: #EEE;
        }

        &.green {
          background-color: #E0F7F0;
          color: #2ECC74;
        }

        &.blue {
          background-color: #E0EEF7;
          color: #38A3FF;
        }

        &.yellow {
          background-color: #FAF4D9;
          color: #FFC600;
        }

        &.red {
          background-color: #F75858;
          color: white;
        }
      }

      .icon {
        margin-right: 8px;
        height: 20px;
      }

      .status {
        margin-left: auto;
        height: 25px;
        line-height: 23px;
        border-radius: 20px;
        border: 1px solid #eee;
        font-size: 12px;
        padding: 0 15px;

        &.blue {
          border-color: var(--primary-color);
          color: var(--primary-color);
        }
      }
    }
  }
}
</style>
